import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { FormErrorComponent, showErrors } from './form-error.component';
import { FormHintComponent } from './form-hint.component';
import { FormLabelComponent } from './form-label.component';

@Component({
    selector: 'nuis-input-text',
    standalone: true,
    imports: [
        NgClass,
        ReactiveFormsModule,
        InputTextModule,
        InputGroupModule,
        InputGroupAddonModule,
        FormLabelComponent,
        FormErrorComponent,
        FormHintComponent,
    ],
    styles: `
        // NOTE: Reset primeng invalid style because we want errors to show after touched
        .p-inputtext.ng-dirty.ng-invalid {
            border-color: #3b82f6;
        }

        .p-inputtext.invalid {
            border-color: #ff3d32 !important; // red-500

            &:focus {
                box-shadow: 0 0 0 0.2rem #ff3d3240 !important;
            }
        }
    `,
    template: `
        <div class="flex flex-column gap-2">
            @if (label(); as label) {
                <nuis-form-label [label]="label" [control]="control()" />
            }

            <p-inputGroup styleClass="w-full">
                <input
                    class="w-full"
                    [ngClass]="{ invalid: showErrors(control()) }"
                    pInputText
                    type="text"
                    [formControl]="control()" />

                @if (postfix(); as postfix) {
                    <p-inputGroupAddon>{{ postfix }}</p-inputGroupAddon>
                }
            </p-inputGroup>

            @if (hint(); as hint) {
                <nuis-form-hint [hint]="hint" />
            }

            @if (showErrors(control())) {
                <nuis-form-error [label]="label()" [control]="control()" />
            }
        </div>
    `,
})
export class InputTextComponent {
    public label = input<string | null>(null);
    public control = input.required<FormControl<string | null>>();
    public postfix = input<string | null>(null);
    public hint = input<string | null>(null);

    protected showErrors = showErrors;
}

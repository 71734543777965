<p-dropdown
    styleClass="w-15rem"
    [ngModel]="selectedView()"
    [options]="views()"
    (onChange)="selectView.emit($event.value)">
    <ng-template pTemplate="selectedItem" let-view>
        <div>{{ view.label | translate }}</div>
    </ng-template>

    <ng-template let-view pTemplate="item">
        <div class="flex align-items-center">
            <div class="flex-1">{{ view.label | translate }}</div>
            @if (view.isStandardView === false) {
                <div class="flex align-items-center">
                    <div>
                        <p-button
                            styleClass="z-5"
                            [text]="true"
                            size="small"
                            icon="pi pi-pencil"
                            [pTooltip]="'tooltip.edit' | translate"
                            tooltipPosition="left"
                            (onClick)="handleEdit($event, view)" />
                    </div>
                    <div>
                        <p-button
                            styleClass="z-5"
                            [text]="true"
                            size="small"
                            severity="danger"
                            icon="pi pi-trash"
                            [pTooltip]="'tooltip.delete' | translate"
                            tooltipPosition="left"
                            (onClick)="handleRemove($event, view)" />
                    </div>
                </div>
            }
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <p-button
            styleClass="w-full"
            severity="success"
            [text]="true"
            icon="pi pi-plus"
            [pTooltip]="'tooltip.add' | translate"
            tooltipPosition="bottom"
            (onClick)="handleCreate($event)" />
    </ng-template>
</p-dropdown>

import { Component, DestroyRef, inject, input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export function showErrors(control: AbstractControl): boolean {
    return control.invalid && control.touched;
}

@Component({
    selector: 'nuis-form-error',
    standalone: true,
    imports: [],
    template: `
        <div class="flex flex-column gap-2 text-sm text-red-500">
            @for (error of errorMessages(); track error) {
                <div>{{ error }}</div>
            }
        </div>
    `,
})
export class FormErrorComponent implements OnInit {
    private readonly translate = inject(TranslateService);
    private readonly destroyRef = inject(DestroyRef);

    public label = input.required<string | null>();
    public control = input.required<AbstractControl>();

    protected errorMessages = signal<string[]>([]);

    public ngOnInit() {
        const errorMessages = this.getErrorMessages();
        this.errorMessages.set(errorMessages);

        this.control()
            .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                const errorMessages = this.getErrorMessages();
                this.errorMessages.set(errorMessages);
            });
    }

    private getErrorMessages() {
        const label = this.label() ?? '';
        const control = this.control();

        return Object.entries(control.errors ?? {}).map(([key, value]) => {
            switch (key) {
                case 'required':
                    return this.translate.instant('validation.required', { label: label });
                case 'minlength':
                    return this.translate.instant('validation.minLength', { ...value, label: label });
                case 'maxlength':
                    return this.translate.instant('validation.maxLength', { ...value, label: label });
                case 'min':
                    return this.translate.instant('validation.min', { ...value, label: label });
                case 'max':
                    return this.translate.instant('validation.max', { ...value, label: label });

                // Custom error
                case 'fileNameExists':
                    return this.translate.instant('validation.fileNameExists', { label: label });
                case 'fileNameCheckingFailed':
                    return this.translate.instant('validation.fileNameCheckingFailed', { label: label });
                case 'notesEitherSubjectOrBody':
                    return this.translate.instant('validation.notesEitherSubjectOrBody');

                default:
                    return this.translate.instant('validation.unknown');
            }
        });
    }
}

import { AbstractControl } from '@angular/forms';

export function setOrClearError(
    control: AbstractControl,
    error: {
        key: string;
        errorValue: unknown | null;
    },
) {
    let errors = control.errors ?? {};
    if (error.errorValue == null) {
        delete errors[error.key];
    } else {
        errors = { ...errors, [error.key]: error.errorValue };
    }

    control.setErrors(Object.keys(errors).length > 0 ? errors : null);
}

import { Component, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';

@Component({
    selector: 'nuis-select-header',
    standalone: true,
    imports: [TranslatePipe, FormsModule, CheckboxModule, TooltipModule],
    template: `
        <p-checkbox
            [ngModel]="isAllSelected()"
            (ngModelChange)="select.emit($event)"
            [binary]="true"
            [disabled]="disabled()"
            [tooltipDisabled]="disabled()"
            [pTooltip]="'actions.checkAll' | translate"
            tooltipPosition="top" />
    `,
})
export class SelectHeaderComponent {
    public isAllSelected = input.required<boolean>();
    public disabled = input.required<boolean>();
    public select = output<boolean>();
}

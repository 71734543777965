import { Component, input } from '@angular/core';

@Component({
    selector: 'nuis-form-hint',
    standalone: true,
    imports: [],
    template: ` <div class="text-sm text-500">{{ hint() }}</div> `,
})
export class FormHintComponent {
    public hint = input.required<string>();
}

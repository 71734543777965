import { TranslateService } from '@ngx-translate/core';
import { largeDialogSettings, openVoidDialog } from '@nuis/common';
import { DialogService } from 'primeng/dynamicdialog';
import { ViewEditorDialogOptions } from './view-editor-dialog-options.type';
import { ViewEditorDialogComponent } from './view-editor-dialog.component';

export async function openViewEditor<T>(
    dialogService: DialogService,
    translate: TranslateService,
    options: ViewEditorDialogOptions<T>,
): Promise<void> {
    await openVoidDialog(dialogService, ViewEditorDialogComponent, {
        ...largeDialogSettings,
        header: translate.instant('viewEditor.title'),
        data: options,
    });
}

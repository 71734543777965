import { Component, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';

@Component({
    selector: 'nuis-select-column',
    standalone: true,
    imports: [FormsModule, CheckboxModule, TooltipModule],
    template: `
        <p-checkbox
            [ngModel]="isSelected()"
            (ngModelChange)="select.emit($event)"
            [binary]="true"
            [disabled]="disabled()"
            [tooltipDisabled]="!disabled()"
            [pTooltip]="disabledHint()"
            tooltipPosition="top" />
    `,
})
export class SelectColumnComponent {
    public isSelected = input.required<boolean>();
    public disabled = input.required<boolean>();
    public disabledHint = input<string>();
    public select = output<boolean>();
}

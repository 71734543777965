import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormErrorComponent, showErrors } from './form-error.component';
import { FormHintComponent } from './form-hint.component';
import { FormLabelComponent } from './form-label.component';

@Component({
    selector: 'nuis-input-textarea',
    standalone: true,
    imports: [
        NgClass,
        ReactiveFormsModule,
        InputTextareaModule,
        FormLabelComponent,
        FormErrorComponent,
        FormHintComponent,
    ],
    styles: `
        // NOTE: Reset primeng invalid style because we want errors to show after touched
        .p-inputtextarea.ng-dirty.ng-invalid {
            border-color: #3b82f6;
        }

        .p-inputtextarea.invalid {
            border-color: #ff3d32 !important; // red-500
            box-shadow: 0 0 0 0.2rem #ff3d3240 !important;
        }
    `,
    template: `
        <div class="flex flex-column gap-2">
            @if (label(); as label) {
                <nuis-form-label [label]="label" [control]="control()" />
            }

            <textarea
                class="w-full"
                [ngClass]="{ invalid: showErrors(control()) }"
                pInputTextarea
                [formControl]="control()"
                rows="3"
                [autoResize]="true"></textarea>

            @if (hint(); as hint) {
                <nuis-form-hint [hint]="hint" />
            }

            @if (showErrors(control())) {
                <nuis-form-error [label]="label()" [control]="control()" />
            }
        </div>
    `,
})
export class InputTextareaComponent {
    public label = input<string | null>(null);
    public control = input.required<FormControl<string | null>>();
    public hint = input<string | null>(null);

    protected showErrors = showErrors;
}

import { InjectionToken, Provider } from '@angular/core';

export interface FormConfig {
    showOptionalLabel?: boolean;
}

export const FORM_LABEL_CONFIG = new InjectionToken<FormConfig>('@luis/forms/form.config');

export function provideFormConfig(config: FormConfig): Provider[] {
    return [{ provide: FORM_LABEL_CONFIG, useValue: config }];
}

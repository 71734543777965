import { CdkDragDrop, DragDropModule, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, inject, OnInit, signal } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { injectDialogOptions, newGuid } from '@nuis/common';
import { InputTextComponent } from '@nuis/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewDto } from '../../dtos';
import { GridColumn } from '../../models';
import { ViewEditorDialogOptions } from './view-editor-dialog-options.type';

@Component({
    selector: 'nuis-view-editor-dialog',
    standalone: true,
    imports: [TranslatePipe, ButtonModule, DragDropModule, InputTextComponent],
    templateUrl: './view-editor-dialog.component.html',
    styleUrls: ['./view-editor-dialog.component.scss'],
})
export class ViewEditorDialogComponent<T> implements OnInit {
    private readonly options = injectDialogOptions<ViewEditorDialogOptions<T>>();
    private readonly dialogRef = inject(DynamicDialogRef);
    private readonly fb = inject(NonNullableFormBuilder);

    public view = signal<ViewDto | null>(this.options.view);
    public allColumns = signal<GridColumn<T>[]>(this.options.allGridColumns);

    protected labelControl = this.fb.control<string>(this.view()?.label ?? '', [Validators.required]);
    protected availableColumns: GridColumn<T>[] = [];
    protected usedColumns: GridColumn<T>[] = [];
    protected isSaving = signal<boolean>(false);

    public ngOnInit() {
        const usedFields = this.view()?.fields ?? [];

        this.availableColumns = this.allColumns().filter((c) => !usedFields.includes(c.field));
        this.usedColumns = this.allColumns().filter((c) => usedFields.includes(c.field));
    }

    protected drop(event: CdkDragDrop<GridColumn<T>[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }
    }

    protected cancel() {
        this.dialogRef.close();
    }

    protected async save() {
        this.labelControl.markAsTouched();
        if (this.labelControl.invalid) {
            return;
        }

        const result: ViewDto = {
            id: this.view()?.id ?? newGuid(),
            label: this.labelControl.value,
            fields: this.usedColumns.map((column) => column.field),
            isStandardView: false,
        };

        try {
            this.isSaving.set(true);
            this.options.onSave(result);
            this.dialogRef.close();
        } catch (error) {
            console.error(error);
        } finally {
            this.isSaving.set(false);
        }
    }
}

import { NgClass } from '@angular/common';
import { Component, DestroyRef, inject, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { explicitEffect } from 'ngxtension/explicit-effect';
import { CalendarModule } from 'primeng/calendar';
import { distinctUntilChanged } from 'rxjs';
import { FormErrorComponent, showErrors } from './form-error.component';
import { FormHintComponent } from './form-hint.component';
import { FormLabelComponent } from './form-label.component';

@Component({
    selector: 'nuis-input-date',
    standalone: true,
    imports: [NgClass, FormsModule, CalendarModule, FormLabelComponent, FormErrorComponent, FormHintComponent],
    styles: `
        .invalid ::ng-deep {
            .p-calendar:not(.p-calendar-disabled) {
                .p-inputtext {
                    border-color: #ff3d32; // red-500
                }
                .p-inputtext:focus {
                    box-shadow: 0 0 0 0.2rem #ff3d3240 !important;
                }
            }
        }
    `,
    template: `
        <div class="flex flex-column gap-2">
            @if (label(); as label) {
                <nuis-form-label [label]="label" [control]="control()" />
            }

            <p-calendar
                styleClass="w-full"
                [ngClass]="{ invalid: showErrors(control()) }"
                [ngModel]="value"
                (ngModelChange)="valueChange($event)"
                [disabled]="control().disabled"
                dateFormat="dd.mm.yy"
                appendTo="body"
                (onBlur)="onBlur()" />

            @if (hint(); as hint) {
                <nuis-form-hint [hint]="hint" />
            }

            @if (showErrors(control())) {
                <nuis-form-error [label]="label()" [control]="control()" />
            }
        </div>
    `,
})
export class InputDateComponent {
    private readonly destroyRef = inject(DestroyRef);

    public label = input<string | null>(null);
    public control = input.required<FormControl<DateTime | null>>();
    public hint = input<string | null>(null);

    // NOTE: We have to work with Date internally because primeng
    //       doesn't support luxon's DateTime.
    protected value: Date | null = null;

    private init = explicitEffect([this.control], ([control]) => {
        this.value = control.value?.toJSDate() ?? null;

        control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged()).subscribe((value) => {
            this.value = value?.toJSDate() ?? null;
        });
    });

    protected valueChange(value: Date | null) {
        this.value = value;

        this.control().setValue(value !== null ? DateTime.fromJSDate(value) : null);
        this.control().markAsTouched();
        this.control().markAsDirty();
    }

    protected onBlur() {
        this.control().markAsTouched();
    }

    protected showErrors = showErrors;
}

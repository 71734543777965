import { NgClass } from '@angular/common';
import { Component, DestroyRef, inject, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { explicitEffect } from 'ngxtension/explicit-effect';
import { CalendarModule } from 'primeng/calendar';
import { distinctUntilChanged } from 'rxjs';
import { DateRange } from '../models';
import { FormErrorComponent, showErrors } from './form-error.component';
import { FormHintComponent } from './form-hint.component';
import { FormLabelComponent } from './form-label.component';

@Component({
    selector: 'nuis-input-date-range',
    standalone: true,
    imports: [
        NgClass,
        FormsModule,
        ReactiveFormsModule,
        CalendarModule,
        FormLabelComponent,
        FormErrorComponent,
        FormHintComponent,
    ],
    styles: `
        .invalid ::ng-deep {
            .p-calendar:not(.p-calendar-disabled) {
                .p-inputtext {
                    border-color: #ff3d32; // red-500
                }
                .p-inputtext:focus {
                    box-shadow: 0 0 0 0.2rem #ff3d3240 !important;
                }
            }
        }
    `,
    template: `
        <div class="flex flex-column gap-2">
            @if (label(); as label) {
                <nuis-form-label [label]="label" [control]="control()" />
            }

            <div class="flex align-items-center gap-1">
                <p-calendar
                    styleClass="w-full"
                    [ngClass]="{ invalid: showErrors(control()) }"
                    [ngModel]="start"
                    (ngModelChange)="startChanged($event)"
                    [disabled]="control().disabled"
                    dateFormat="dd.mm.yy"
                    appendTo="body"
                    (onBlur)="onBlur()" />

                <div>-</div>

                <p-calendar
                    styleClass="w-full"
                    [ngClass]="{ invalid: showErrors(control()) }"
                    [ngModel]="end"
                    (ngModelChange)="endChanged($event)"
                    [disabled]="control().disabled"
                    dateFormat="dd.mm.yy"
                    appendTo="body"
                    (onBlur)="onBlur()" />
            </div>

            @if (hint(); as hint) {
                <nuis-form-hint [hint]="hint" />
            }

            @if (showErrors(control())) {
                <nuis-form-error [label]="label()" [control]="control()" />
            }
        </div>
    `,
})
export class InputDateRangeComponent {
    private readonly destroyRef = inject(DestroyRef);

    public label = input<string | null>(null);
    public control = input.required<FormControl<DateRange | null>>();
    public hint = input<string | null>(null);

    // NOTE: We have to work with Date internally because primeng
    //       doesn't support luxon's DateTime.
    protected start: Date | null = null;
    protected end: Date | null = null;

    public init = explicitEffect([this.control], ([control]) => {
        this.start = control.value?.start?.toJSDate() ?? null;
        this.end = control.value?.end?.toJSDate() ?? null;

        control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged()).subscribe((value) => {
            this.start = value?.start?.toJSDate() ?? null;
            this.end = value?.end?.toJSDate() ?? null;
        });
    });

    protected startChanged(value: Date | null) {
        this.start = value;

        this.valueChange({
            start: value !== null ? DateTime.fromJSDate(value) : null,
            end: this.control().value?.end ?? null,
        });
    }

    protected endChanged(value: Date | null) {
        this.end = value;

        this.valueChange({
            start: this.control().value?.start ?? null,
            end: value !== null ? DateTime.fromJSDate(value) : null,
        });
    }

    private valueChange(range: DateRange) {
        const value: DateRange | null = range.start !== null || range.end !== null ? range : null;

        this.control().setValue(value);
        this.control().markAsTouched();
        this.control().markAsDirty();
    }

    protected onBlur() {
        this.control().markAsTouched();
    }

    protected showErrors = showErrors;
}
